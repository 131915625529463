.pagination-nav {
	float: left;
	width: 100%;

	ul {
		text-align: center;
	}

	li {
		display: inline-block;
		margin: 0 2px;

		// MOBILE
		@include mq(s) {
			display: none;

			&.prev, &.next, &.active {
				display: inline-block;
			}
		}

		&.active {
			a {
				background: $cor_primaria;
				color: #FFF;
			}
		}
	}

	a {
		display: inline-block;
		width: 35px;
		height: 35px;
		line-height: 33px;
		border-radius: 10px;
		font-family: $fonte_geral;
		color: $cor_fonte_escura;
		text-align: center;
		font-weight: bold;
		transition: .3s ease-out;
		
		&:hover {
			background: rgba(0,0,0,.3);
		}
	}
}