// Fotos (div)
.produto-detalhe-fotos {
    margin-right: 30px;
    margin-bottom: 10px;

	// MOBILE
	@include mq(s) {
        margin-right: 0px;
	}
}

// Corrosel
.produto-fotos-carrosel {
	margin-top: 10px;

	// Thumb
	.produto-carrosel-thumb {
		margin: 0 5px;
		cursor: pointer;
    border: 1px solid #DDD;
	}
}

// Texto
.produto-detalhe-texto {
	padding: 0 10px;

	// BOTÃO
	.btn-container {
		float: none;

		// MOBILE
		@include mq(s) {
			text-align: center;
		}
	}
}
.produto-foto-dest {
  border: 1px solid #DDD;
}
