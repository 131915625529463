// Fundo (Máscara preta transparente que fica por trás do modal)
.modal { 
    position: fixed; 
    z-index: $z_index_modal; 
    top: 0; 
    left: 0; 
    bottom: 0; 
    background: rgba(0,0,0,.5); 
    width: 100%; 
    height: 0; 
    transition: background .3s;

    &.open {
        height: auto;
        overflow-y: auto; 
        overflow-x: hidden; 
        -ms-overflow-style: none;
    }

    &.open::-webkit-scrollbar {
        display: none;
    }
}

// Caixa do modal
.modal-wrap { 
    position: absolute; 
    left: 0; 
    right: 0; 
    margin: 30px auto; 
    background: #FFF; 
    width: 95%; 
    max-width: 600px; 
    height: auto; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); 
    visibility: hidden; 
    opacity: 0; 
    -webkit-transform: translate(0,-100px); 
    transform: translate(0,-100px);
    transition: .5s;  

    // Caixa grande
    &.modal-lg { 
        max-width: 800px;
    }

    // Caixa pequena
    &.modal-sm { 
        max-width: 400px;
    }
}
.modal { 
    &.open {
        .modal-wrap {
            visibility: visible; 
            opacity: 1; 
            -webkit-transform: translate(0,0); 
            transform: translate(0,0);
        }
    }
} 

// Header 
.modal-header { 
    float: left; 
    width: 100%; 
    padding: 15px; 
    background: none;
}

// Título
.modal-titulo { 
    display: block;
    font-family: $fonte_titulo;
    font-size: 24px;
    font-weight: bold;
    color: $cor_primaria;
    text-align: center;
    text-transform: uppercase;

    // MOBILE
    @include mq(s) {
        font-size: 20px;
    }
}

// 'X' Fechar
.modal-btn-close { 
    position: absolute; 
    top: 12px; 
    right: 15px; 
    width: 20px; 
    height: 20px; 
    cursor: pointer; 

    &::before, &::after { 
        content: ''; 
        display: block; 
        width: 100%; 
        height: 2px; 
        background: #CCC; 
        position: absolute; 
        top: 50%; 
        -webkit-transition: background .3s ease-out; 
        transition: background .3s ease-out; 
    }

    &:hover::before, &:hover::after { 
        background: $cor_primaria;
    }

    &::before { 
       -webkit-transform: rotate(45deg); 
       transform: rotate(45deg);
    }

    &::after { 
       -webkit-transform: rotate(-45deg); 
       transform: rotate(-45deg);
    }
}
.modal-force {
    .modal-btn-close {
        display: none;
    }
}

// Corpo do modal
.modal-body { 
    float: left; 
    width: 100%; 
    padding: 30px; 
    padding-top: 0px;

    // MOBILE
    @include mq(s) {
        padding-top: 0px;
        padding: 20px; 
    }
}

// Classes para alinhamento dos botões dentro do modal
.modal-btn {
    margin-top: 20px;

    &.center {
        float: left;
        width: 100%;
        text-align: center;
    }

    &.left {
        float: left;
        text-align: left;
        margin-right: 20px;
    }

    &.right {
        float: right;
        text-align: right;
        margin-left: 20px;
    }
}

// Alerta
#modal-alert {

    // Ícone
    .modal-alert-icon {
        float: left;
        width: 50px;
        height: 50px;
        text-align: center;

        i {
            font-size: 36px;
            color: $cor_fonte_escura;

            &:not(.fa-exclamation-circle) {
                display: none;
            }
        }
    }

    // Texto
    .texto {
        float: left;
        width: calc(100% - 50px);
        padding-left: 10px;
        color: $cor_fonte_clara;
    }

    // Sucesso
    &.success {
        // Fechar
        .modal-btn-close::before, .modal-btn-close::after { 
            background: $success_color; 
        }

        // Título
        .modal-titulo { 
            color: $success_color;
        }

        // Ícone
        .modal-alert-icon i {  
            color: $success_color;
            display: none;

            &.fa-check-circle {
                display: inline;
            }
        }
    }

    // Error
    &.error {
        // Fechar
        .modal-btn-close::before, .modal-btn-close::after { 
            background: $error_color; 
        }

        // Título
        .modal-titulo { 
            color: $error_color;
        }

        // Ícone
        .modal-alert-icon i {  
            color: $error_color;
            display: none;

            &.fa-times-circle {
                display: inline;
            }
        }
    }

    // Warning
    &.warning {
        // Fechar
        .modal-btn-close::before, .modal-btn-close::after { 
            background: $warning_color; 
        }

        // Título
        .modal-titulo { 
            color: $warning_color;
        }

        // Ícone
        .modal-alert-icon i {  
            color: $warning_color;
            display: none;

            &.fa-exclamation-circle {
                display: inline;
            }
        }
    }
}