.segura-lateral {

    // MOBILE
    @include mq(s) {
        margin-bottom: 25px;
    }

  .btn-container {
    margin-top: 0px;
  }
}

.produtos-categorias-menu {
    margin-bottom: 40px;

    // MOBILE
    @include mq(s) {
        margin-bottom: 0px;
        display: none;
    }

    // TABLET
    @include mq(m) {
        margin-top: 20px;
        display: none;
    }

    // Link
    a { 
        position: relative;
        display: inline-block;
        font-size: 14px;
        padding: 8px;
        margin: 4px;
        transition: .3s ease-out;
        font-family: $fonte_titulo;

        // MOBILE
        @include mq(s) {
            //float: left;
            font-size: 14px;
            width: 100%;
            text-align: center;
        }

    }

    // Subcategorias
    .produtos-categorias li ul {
        margin-top: 20px;

        // Item
        li {
            margin-bottom: 10px;
        }

        // Link
        a { 
            position: relative;
            display: inline-block;
            font-size: 16px;
            color: $cor_fonte_clara;

            &:hover {
                color: $cor_secundaria;
            }
        }
    }

}

.toggle-produtos-categorias-menu{
    color: $cor_fonte_escura;
    text-align: center;
    display: none;
    padding: 10px;
    cursor: pointer;
    //border: 1px solid $cor_primaria;

    // MOBILE
    @include mq(s) {
        display: block;
        margin-bottom: 0px;
    }

    i {
        font-size: 12px;
        margin-left: 5px;
        transition: .3s ease-out;
    }

    //ATIVO 
    &.active {

        i {
         transform: rotate(90deg);
        }

    }
}
