// Post detalhe
.post-detalhe {

    // Título
    > h1 {
        float: left;
        width: 100%;
        font-size: 28px;
        line-height: 1.2;
        color: $cor_fonte_escura;
        font-weight: bold;
        margin-bottom: 30px;

        // TABLET
        @include mq(m) {
            font-size: 24px;     
        }

        // MOBILE
        @include mq(s) {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    // Autor e Data
    .post-detalhe-data {     
        // MOBILE
        @include mq(s) {
            font-size: 14px;
        }    
    
        b {
            color: $cor_fonte_escura;
            font-weight: bold;
        }
    }

    .post-detalhe-share {
        margin-top: -5px;
        text-align: right;

        // MOBILE
        @include mq(s) {
            margin-top: 10px;
            text-align: left;
        }
    }

    // Foto
    .post-detalhe-foto {
        float: left;
        width: 100%;
        border: 1px solid #DDD;
        margin-bottom: 20px;    
        margin-top: 10px; 
        overflow: hidden;   
    }
}

// Compartilhamento
.post-share {
    display: inline-block;
    width: 100%;
    text-align: right;

    // MOBILE
    @include mq(s) {
        text-align: left;
    }

    // Itens
    > a {
        float: none !important;
        display: inline-block !important;
    }

    // Texto
    > label {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
    }
}

// Comentários
.blog-comentarios {
    float: left;
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #DDD;

    // Botão
    .btn-container {
        margin-top: 0px;
        margin-bottom: 20px;

        // MOBILE
        @include mq(s) {
            margin-top: 20px;
        }
    }
}

// Lista comentários
.blog-comentarios-lista {
    float: left;
    width: 100%;
    
    // Comentário
    .blog-comentario {
        position: relative;
        float: left;
        width: 100%;
        background: $cor_fundo2;
        border: 1px solid #DDD;
        padding: 20px;
        margin-bottom: 20px;

        // Nome
        .blog-comentario-nome {
            font-size: 16px;
            color: $cor_fonte_escura;
            font-weight: bold;
        }

        // Data
        .blog-comentario-data {
            position: absolute;
            bottom: 20px;
            right: 20px;
            color: $cor_fonte_clara;
        }

        // Texto
        .blog-comentario-texto {
            color: $cor_fonte_clara;
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
}