// Formulário
.form-contato {
	// Texto
	.texto {
		margin-bottom: 20px;
	}
}

// Lateral
.contato-lateral {
	background: $cor_fundo2;
	border: 1px solid #DDD;
	padding: 30px 20px;
	
	// MOBILE
	@include mq(s) {
		margin-top: 20px;
	}
}

// Lateral item
.contato-item {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0px;
	}

	// Ícone
	.contato-item-icon {
		text-align: center;
		i {
			font-size: 28px;
			color: $cor_primaria;
		}
	}

	// Título
	h3 {
		color: $cor_fonte_escura;
		font-weight: bold;
		text-transform: uppercase;
	}

	// Texto 
	p {
		color: $cor_fonte_escura;
		font-weight: 300;
		margin-bottom: 5px;
	}

	// Link
	a {
		color: $cor_fonte_escura !important;
	}
}

// Mapa
.contato-mapa {
	overflow: hidden;
	margin-top: 40px;
	border: 1px solid #DDD;

	iframe {
		height: 400px !important;
    width: 100% !important;
    margin-bottom: -8px;


		// MOBILE
		@include mq(s) {
			height: 250px !important;
		}
	}

    &.footer-mapa {
      margin-top: 0px;

      iframe {
      height: 247px !important;
      width: 100% !important;
      margin-bottom: -8px;

        // MOBILE
        @include mq(s) {
          height: 150px !important;
        }
      }
    }
}
