/* Seção
.home-produtos {
    background: $cor_fundo2;
}

// Item
.home-produto-item {
    float: left;
    width: 100%;
    
    // MOBILE
    @include mq(s) {
        padding: 0 20px;
    } 
}

// Conteúdo
.home-produto-texto {
    float: left;
    width: 50%;
    height: 380px;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    // TABLET
    @include mq(m) {
        height: 250px;
        padding: 0 20px;
    }

    // MOBILE
    @include mq(s) {
        width: 100%;
        height: auto;
        padding: 30px 0;
    }

    // Título
    .titulo {
        margin-bottom: 20px;

        &::before, &::after {
            // TABLET
            @include mq(m) {
                display: none;       
            }
        }
    }

    h2 {
        font-size: 27px;
        margin-bottom: 10px;
    }

    // Texto
    h3 {
        font-family: $fonte_titulo;
        font-size: 17px;
        font-weight: 300;
        color: $cor_fonte_escura;
        text-align: left;

        // TABLET
        @include mq(m) {
            font-size: 14px;
        }

        // MOBILE
        @include mq(s) {
            font-size: 18px;
        }
    
      } 
}

// Foto
.home-produto-foto {
    position: relative;
    float: left;
    width: 50%;
    overflow: hidden;

    // MOBILE
    @include mq(s) {
        width: 100%;
        max-height: auto;
    }

    img {
        transition: .5s ease-out;
    }
    &:hover img {
        transform: scale(1.1);
    }
}*/

// Bloco
.produto-bloco {
  padding-bottom: 0px;
  padding-top: 0px;

  // Foto
  .produto-bloco-foto {
  overflow: hidden;
    
    figure {
      overflow: hidden;
      transition: .3s ease-out;

      img {
        transition: .3s ease-out;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  // Conteúdo (Textos)
  .produto-bloco-content {

    // Esquerda
    &.right {
      padding-left: 50px;

      // MOBILE
      @include mq(s) {
        padding: 20px;
      }

    }

    // Esquerda
    &.left {

      // TABLET
      @include mq(m) {
        padding: 10px;
      }

       // MOBILE
      @include mq(s) {
        padding: 20px;
      }

    }

    // MOBILE
    @include mq(s) {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding: 15px;
    }

    // Título
    h2 {
      font-size: 32px;
      margin-bottom: 10px;
      color: $cor_fonte_escura;
      font-weight: 400;

      // TABLET
      @include mq(m) {
        font-size: 28px;
      }

      // MOBILE
      @include mq(s) {
        font-size: 24px;
        padding-top: 0px;
      }
    }
  }
}

