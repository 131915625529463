// Footer geral
.footer {
	position: relative;
	float: left;
	width: 100%;
	padding-top: 40px;
	background: $cor_fundo2;
	overflow: hidden;

	// MOBILE
	@include mq(s) {
		padding-bottom: 0px;
	}
}

// Conteúdo container
.footer-content {
	margin-bottom: 40px;
}

// Coluna
.footer-bloco {

	&:not(:last-child) {
		// MOBILE
		@include mq(s) {
			margin-bottom: 40px;
		}
	}

	// Título
	> h2 {
		font-family: $fonte_titulo;
		color: $cor_primaria;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.2;
		//text-transform: uppercase;
		margin-bottom: 20px;

		// MOBILE
		@include mq(s) {
			font-size: 20px;
		}
	}

	// Lista links
	.footer-lista {
		// Item
		li {
			float: left;
			width: 50%;
			margin-bottom: 15px;
			font-size: 16px;
			font-weight: 300;
			line-height: 1.2;
			color: #333;

			&:last-child {
				margin-bottom: 20px;
			}

			// TABLET
			@include mq(m) {
				width: 100%;
			}

			// MOBILE
			@include mq(s) {
				width: 100%;
			}
		}

		// Link
		a {
			color: #333;
			transition: .2s ease-out;

			&:hover {
				color: $cor_primaria;
			}
		}

		// Ícone
		i {
			margin-right: 10px;
		}
	}
}

// Mapa do site
.footer-mapa {}

// Newsletter
.footer-newsletter {
  background: $cor_primaria;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;

  h2{
    color: #FFF;
    font-size: 30px;
    margin-bottom: 0px;
    width: auto;
    float: left;
  }

  // Texto
  .texto {
    font-family: $fonte_titulo;
    font-size: 14px;
    font-weight: normal;
    color: #FFF;
    width: auto;
    float: left;
    margin-top: 11px;
    margin-left: 15px;

    // TABLET
    @include mq(m) {
      margin-left: 0px;
    }

    // MOBILE
    @include mq(s) {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  // Campo
  .campo-container {
    float: left;
    width: calc(100% - 120px);
    margin: 0px;

    // TABLET
    @include mq(m) {
      width: 100%;
      margin-bottom: 10px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
    }

    // Campo
    .campo {      
      height: 40px;     
      border: none;
      
      // DESKTOP
      @include mq(l) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }   

  // Botão
  .btn {
    float: left;
    width: 120px;
    height: 40px;
    padding: 0px;
    font-size: 14px;
    color: #FFF;
    text-transform: none;

    // DESKTOP
    @include mq(l) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    // TABLET
    @include mq(m) {
      width: 100%;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
    }
  }
}


// Atendimento
.footer-atendimento {

	// TABLET
	@include mq(m) {
		padding-left: 20px;
	}

	// MOBILE
	@include mq(s) {
		padding-left: 0px;
	}

	.footer-lista li {
		width: 100%;
	}
}

// Barra footer
.barra-footer {
  position: relative;
  padding-bottom: 17px;
  font-family: $fonte_titulo;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  background: darken($cor_fundo2, 2%);

  // Linha
  .line {
    float: left;
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
    background: rgba(#000, .1);
  }

  [class^="grid-"]:not(.redes-sociais) {
    padding-top: 15px;
  }

  // Copyright
  .copyright {

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }

  // Desenvolvimento
  .development {
    text-align: right;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }

    a {
      color: $cor_fonte_clara !important;
    }
  }
}

// Redes sociais
.redes-sociais {
	text-align: left;

  .facebook {
    background: #3b5998;
    
      i {
        color: #FFF;
        transition: .3s ease-out;
      }
      
      &:hover i {
        color: #3b5998;
      }

      &:hover {
        background: #FFF;
      }

}
  .twitter {
    background: #55acee;

      i {
        color: #FFF;
        transition: .3s ease-out;
      }
      &:hover i {
        color: #55acee;
      }
      &:hover {
        background: #FFF;
      }
  }

  .instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      
      i {
        color: #FFF;
        transition: .3s ease-out;

      }
      &:hover i {
       background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
     }
      &:hover {
        background: #FFF;
      }
  }

	// MOBILE
	@include mq(s) {
		//text-align: center;
	}

	// Item
	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 43px;
		border-radius: 50%;
		text-align: center;
		margin: 0 3px;
		background: $cor_primaria;
		transition: .3s ease-out;

		&:hover {
			background: #FFF;
		}

		i {
			color: #FFF;
			font-size: 20px;
			transition: .3s ease-out;
		}
		&:hover i {
			color: $cor_primaria;
		}
	}
}
