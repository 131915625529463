// Seção
.home-chamada {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;

    // Máscara
    .mascara {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .4);
    }

    // Título
    .titulo {
        color: #FFF;
    }
}

.segura-texto{

    h3 {
        color: #FFF;
    }

}
