// Bloco produto
.bloco-produto {
    position: relative;
    background: #FFF;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-bottom: 20px;

    // Foto
    figure {
        background: #DDD;
        overflow: hidden;

        img {
            transition: .5s ease-out;
        }
    }
    &:hover figure img {
        transform: scale(1.1);
    }

    // Infos
    > div {
        padding: 20px;
        text-align: center;

        // MOBILE
        @include mq(s) {
            padding: 10px;
        }
    }

    // Título
    h2 {
        height: 45px;
        overflow: hidden;
        color: $cor_fonte_escura;
        font-size: 16px;
        line-height: 1.4;
        font-weight: bold;
        transition: .3s ease-out;

        // TABLET
        @include mq(m) {
            height: 70px;
        }

        // MOBILE
        @include mq(s) {
            height: 50px;
            font-size: 12px;
        }
    }
    &:hover h2 {
        color: $cor_primaria;
    }

    // Preço
    h3 {
        span {
            display: inline-block;
        }

        .de {
            color: $cor_fonte_clara;
            text-decoration: line-through;
            margin-right: 5px;
        }

        .por {
            color: $cor_fonte_escura;
            font-weight: bold;
            margin-left: 5px;
        }

        // MOBILE
        @include mq(s) {
            font-size: 13px;
        }
    }

    // Botão
    .btn-container {
        float: none;
        margin-top: 10px;

        .btn {
            // MOBILE
            @include mq(s) {
                width: 100%;
                font-size: 12px;
                padding: 10px 5px;
            }
        }
    }
}
